import { Link } from "gatsby"
import React from "react"

const Prev = ({ current, type }) => {
	if (current === 1) {
		return (
			<li class="pagination__prev not-work"><span>前へ</span></li>
		)
	} else if (current === 2) {
		return (
			<li class="pagination__prev"><Link to={`/`}>前へ</Link></li>
		)
	} else {
		return (
			<li class="pagination__prev"><Link to={`/page/${current - 1}/`}>前へ</Link></li>
		)
	}
}

const Next = ({ num, current, type }) => {
	if (current === num) {
		return (
			<li class="pagination__next not-work"><span>次へ</span></li>
		)
	} else {
		return (
			current === '' ? <li class="pagination__next"><Link to={`/page/2/`}>次へ</Link></li> :
				<li class="pagination__next"><Link to={`/page/${current + 1}/`}>次へ</Link></li>
		)
	}
}

const Pagination = ({ num, current, type }) => {
	return (
        <div class="pager">
            <ul class="pagination">
                <Prev current={current} num={num} type={type} />
                <Next current={current} num={num} type={type} />
            </ul>
        </div>
	)
}

export default Pagination